import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js/max';
import { removeDashBracket } from './parser';

export const PREFERRED_COUNTRIES = [
  'tw',
  'hk',
  'mo',
  'my',
  'us',
  'cn',
  'sg',
  'id',
  'th',
  'vn',
  'ph',
  'jp',
  'kr',
  'au',
  'nz',
  'ca',
];

export const VALIDATION_TYPE = {
  countryRequired: 'countryRequired',
  numberRequired: 'numberRequired',
  numberValidation: 'numberValidation',
  localPhoneValidation: 'localPhoneValidation',
  mobilePhoneValidation: 'mobilePhoneValidation',
};

export const getPreferredCountries = (country) => {
  let preferredCountries = [...PREFERRED_COUNTRIES];
  if (country) {
    const lowerCountry = country.toLowerCase();
    preferredCountries = preferredCountries.filter(
      (country) => country !== lowerCountry,
    );
    preferredCountries.unshift(country);
  }
  return preferredCountries;
};

export const formatPlaceholder = (value, country) => {
  const removeDashPlaceholder = removeDashBracket(value);
  if (!country) {
    return removeDashPlaceholder;
  }
  const nationalPhonePlaceholder = removeDashBracket(
    parsePhoneNumber(
      removeDashPlaceholder,
      country?.toUpperCase(),
    )?.formatNational(),
  );
  return nationalPhonePlaceholder || removeDashPlaceholder;
};

export const formatPhone = (value, country) => {
  const removePlusValue = value.replace(/\+/, '');
  return (
    parsePhoneNumber(removePlusValue, country?.toUpperCase())
      ?.formatNational()
      ?.replace(/\D/g, '') || removePlusValue
  );
};

// In some countries, the format of local calls is the same as that of mobile phones, e.g. US, CA
const FIXED_LINE_TYPES = ['FIXED_LINE_OR_MOBILE', 'FIXED_LINE'];
const MOBILE_TYPES = ['FIXED_LINE_OR_MOBILE', 'MOBILE'];
const HK_MOBILE_PHONE_REGEX = /^[4-9]\d{7}$/;
const isValidPhone = (phone, country) => {
  if (country !== 'HK') {
    return isValidPhoneNumber(phone, country);
  }
  return (
    isValidPhoneNumber(phone, country) || HK_MOBILE_PHONE_REGEX.test(phone)
  );
};

/**
 * return array [], each index is an object, which include following properties
 * type: countryRequired | numberRequired | numberValidation
 * message: which is a string, it's error content for displaying in UI
 * config: you can customize you validation error message by pass in the different translation key, ex.
 * {
 *  invalidKey: {
 *    countryRequired: 'form.validation.calling_code',
 *    numberRequired: 'form.validation.required'
 *  },
 *  wording: {
 *    field_name: 'Phone number'
 *  },
 *  validateTarget: {
 *    numberValidation: true
 *    localPhoneValidation: false
 *    mobilePhoneValidation: true
 *  }
 * }
 * otherwise, it will use default key
 */
export const validatePhone = (phone, propCountry = '', config = {}) => {
  const country = propCountry.toUpperCase();
  const { invalidKey = {}, wording, validateTarget = {} } = config;
  const {
    countryRequired: doCountryRequired = true,
    numberRequired: doNumberRequired = true,
    numberValidation: doNumberValidation = true,
    localPhoneValidation: doLocalPhoneValidation = false,
    mobilePhoneValidation: doMobilePhoneValidation = false,
  } = validateTarget;
  const {
    countryRequired = 'form.validation.calling_code',
    numberRequired = 'form.validation.phone.required',
    numberValidation = 'form.validation.phone.error',
    localPhoneValidation = 'form.validation.phone.error',
    mobilePhoneValidation = 'form.validation.phone.error',
  } = invalidKey;
  const errorMsg = [];
  if (doCountryRequired && !country) {
    errorMsg.push({
      type: VALIDATION_TYPE.countryRequired,
      message: { key: countryRequired, wording },
    });
  }
  if (doNumberRequired && !phone) {
    errorMsg.push({
      type: VALIDATION_TYPE.numberRequired,
      message: { key: numberRequired, wording },
    });
  }
  if (doNumberValidation && phone.length > 0 && !isValidPhone(phone, country)) {
    errorMsg.push({
      type: VALIDATION_TYPE.numberValidation,
      message: { key: numberValidation, wording },
    });
  }
  const instance = parsePhoneNumber(phone, country);
  if (
    doLocalPhoneValidation &&
    !FIXED_LINE_TYPES.includes(instance?.getType?.())
  ) {
    errorMsg.push({
      type: VALIDATION_TYPE.localPhoneValidation,
      message: { key: localPhoneValidation, wording },
    });
  }
  if (
    doMobilePhoneValidation &&
    ((country === 'HK' && !HK_MOBILE_PHONE_REGEX.test(phone)) ||
      (country !== 'HK' && !MOBILE_TYPES.includes(instance?.getType?.())))
  ) {
    errorMsg.push({
      type: VALIDATION_TYPE.mobilePhoneValidation,
      message: { key: mobilePhoneValidation, wording },
    });
  }
  return errorMsg;
};
