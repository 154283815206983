/**
 * TODO:
 * 1. extract the LINE API part from benchat API CRUD to another service
 * 2. Modify API to support the real bulk update
 */
import axios from 'axios';
import { flow } from 'lodash-es';

export const generateRandomNumber = () =>
  Math.floor(Math.random() * 10000000000000000);

export const getBenchatService = function (
  ajaxPackage,
  generateState = generateRandomNumber,
) {
  const state = generateState();

  return {
    getSubscriptions: function (customerId) {
      return ajaxPackage.get('/api/benchat/subscriptions', {
        params: { userId: customerId },
      });
    },
    getLineActiveSubscriptions: function (topics, subscriptions) {
      return _.reduce(
        subscriptions,
        function (acc, subscription) {
          _.each(topics, function (topic) {
            if (
              subscription.isActive &&
              subscription.topic === topic &&
              subscription.platform === 'LINE_MES_API'
            ) {
              acc.push(subscription);
            }
          });
          return acc;
        },
        [],
      );
    },
    getFacebookActiveSubscriptions: function (topics, subscriptions) {
      return _.reduce(
        subscriptions,
        function (acc, subscription) {
          _.each(topics, function (topic) {
            if (
              subscription.isActive &&
              subscription.topic === topic &&
              subscription.platform === 'FACEBOOK'
            ) {
              acc.push(subscription);
            }
          });
          return acc;
        },
        [],
      );
    },
    activateSubscription: function (subscriptionId, subscriptionsParams) {
      return ajaxPackage.put(
        '/api/benchat/subscriptions/' + subscriptionId + '/activate',
        subscriptionsParams,
      );
    },
    deactivateSubscription: function (subscriptionId) {
      return ajaxPackage.put(
        '/api/benchat/subscriptions/' + subscriptionId + '/deactivate',
      );
    },
    createSubscription: function (subscriptionParams) {
      return ajaxPackage.post('/api/benchat/subscriptions', {
        subscription: subscriptionParams,
      });
    },
    patchSubscriptions: function (subscriptionsParams) {
      return ajaxPackage.patch(
        '/api/benchat/subscriptions',
        subscriptionsParams,
      );
    },
    generateUserRef: function (customerIdentifier) {
      // user-ref has to be unique on each rendering, so a random nonce is appended
      return `${customerIdentifier}-${generateState()}`;
    },
    getLineLoginUri: function (channelId) {
      const urlQueries = new URLSearchParams(window.location.search);
      const encryptDataParams = urlQueries.get('encrypt_data');
      const promotionId = urlQueries.get('promotion_id');
      const from = urlQueries.get('from');

      let lineLoginUri =
        'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&bot_prompt=aggressive&state=' +
        state +
        '&scope=profile openid email phone&client_id=' +
        channelId +
        '&redirect_uri=' +
        window.location.origin +
        '/users/auth/line/setup';

      if (from) {
        lineLoginUri += `?from=${from}`;
      }

      if (encryptDataParams) {
        // URLSearchParams will decode params once and line redirect will decode again
        // so we need to encode twice to handle uri contains plus symbol case
        const encryptData = flow(
          window.encodeURIComponent,
          window.encodeURIComponent,
        )(encryptDataParams);
        const uriDataParams = lineLoginUri.includes('setup?')
          ? `&encrypt_data=${encryptData}`
          : `?encrypt_data=${encryptData}`;
        lineLoginUri += uriDataParams;
      }

      if (promotionId) {
        const promotionParams = lineLoginUri.includes('setup?')
          ? `&promotion_id=${promotionId}`
          : `?promotion_id=${promotionId}`;
        lineLoginUri += promotionParams;
      }

      return lineLoginUri;
    },
    getLineMessageSubscriptionPopupUri: function (
      channelId,
      customerIdentifier,
    ) {
      const query = this.generateLineQuery(customerIdentifier, 'POPUP');
      return (
        'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&prompt=consent&bot_prompt=aggressive&state=' +
        state +
        '&scope=profile openid&client_id=' +
        channelId +
        '&redirect_uri=' +
        this.getLineOAuthRedirectUri(customerIdentifier, query)
      );
    },
    getLineMessageSubscriptionRedirectToUri: function (
      channelId,
      customerIdentifier,
    ) {
      const query = this.generateLineQuery(customerIdentifier, 'REDIRECT_TO');
      return (
        'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&prompt=consent&bot_prompt=aggressive&state=' +
        state +
        '&scope=profile openid&client_id=' +
        channelId +
        '&redirect_uri=' +
        this.getLineOAuthRedirectUri(customerIdentifier, query)
      );
    },
    generateLineQuery: function (customerIdentifier, action) {
      return (
        customerIdentifier +
        '-' +
        action +
        '-' +
        window.location.pathname +
        window.location.search
      );
    },
    getLineOAuthRedirectUri: function (customerIdentifier, query) {
      return window.location.origin + '/oauth/line/callback?query=' + query;
    },
    getLineQuickSignupUri: function (channelId) {
      return (
        'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&bot_prompt=aggressive&state=' +
        state +
        '&scope=profile openid email phone&client_id=' +
        channelId +
        '&redirect_uri=' +
        window.location.origin +
        '/oauth/line/quick_sign_up_callback'
      );
    },
    getChannel: function (channelParams) {
      return ajaxPackage.get('/api/benchat/channel', { params: channelParams });
    },
    getRule: function (ruleParams) {
      return ajaxPackage.get('/api/benchat/rules', { params: ruleParams });
    },
  };
};

export default getBenchatService(axios);
