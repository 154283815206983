import { isDate, isEmpty, isUndefined } from 'lodash-es';
import dayjs from 'dayjs';

app.directive('timeSlotOptions', [
  '$filter',
  'timeSlotService',
  function ($filter, timeSlotService) {
    const componentTemplate = `
      <div>
        <div class="form-group time-slots" ng-show="enabledTimeSlots">
          <div class="time-slots-title">{{"tcat.time_slot.title" | translate}}</div>
          <div class="select-cart-form">
            <select
              class="form-control"
              name="tcat.time_slot.title"
              ng-model="timeSlot"
              ng-change="onChange(timeSlot)" 
              ng-required="isRequired()"
              ng-options="timeSlot as timeSlot.description disable when timeSlot.disabled for timeSlot in timeSlotOptions">
              <option value="" ng-if="showDefaultOption" >{{"dropdown.hint" | translate}}</option>
            </select>
          </div>
        </div>
        <input id="time_slot_key" type="hidden" name="order[delivery_data][time_slot_key]"/>
        <input id="delivery_time_slot_id" type="hidden" name="order[delivery_data][delivery_time_slot_id]"/>
        <input id="time_slot_group_key" type="hidden" name="order[delivery_data][time_slot_group_key]"/>
      </div>
    `;

    return {
      restrict: 'E',
      replace: true,
      template: componentTemplate,
      scope: {
        timeSlots: '=',
        timeSlotsTranslations: '=',
        deliveryOptionId: '=',
        deliveryOptionConfig: '=',
        deliveryTimeSlotRequired: '=',
        deliveryDate: '=',
        onTimeSlotChange: '&?',
        cartTagId: '=',
      },
      link: function (scope, element) {
        scope.timeSlotOptions = [];
        scope.timeSlotId = '';
        scope.timeSlotKey = '';
        scope.timeSlotGroupKey = '';
        scope.isTimeSlotWithLimitedQuantity = false;

        scope.onChange = (item) => {
          if (isEmpty(item)) {
            element.find('#delivery_time_slot_id').val('');
            element.find('#time_slot_key').val('');
            element.find('#time_slot_group_key').val('');
            scope.onTimeSlotChange?.({
              value: {
                cartTagId: scope.cartTagId,
                delivery_time_slot_id: '',
                time_slot_key: '',
                time_slot_group_key: '',
              },
            });
            return;
          }

          if (scope.isTimeSlotWithLimitedQuantity) {
            element.find('#delivery_time_slot_id').val(item.key);
            element.find('#time_slot_key').val(item.description);
            element.find('#time_slot_group_key').val(item.groupKey);
            scope.onTimeSlotChange?.({
              value: {
                cartTagId: scope.cartTagId,
                delivery_time_slot_id: item.key,
                time_slot_key: item.description,
                time_slot_group_key: item.groupKey,
              },
            });
            return;
          }

          element.find('#time_slot_key').val(item.key);
          scope.onTimeSlotChange?.({
            value: {
              cartTagId: scope.cartTagId,
              time_slot_key: item.key,
            },
          });
        };

        function init() {
          scope.isTimeSlotWithLimitedQuantity = timeSlotService.isFeatureEnabled(
            scope.deliveryOptionConfig,
          );

          scope.isTimeSlotWithLimitedQuantity
            ? initTimeSlotWithLimitedQuantity()
            : initTimeSlot();
        }

        function initTimeSlotWithLimitedQuantity() {
          scope.enabledTimeSlots =
            scope.deliveryOptionConfig.delivery_time_slot_enable &&
            timeSlotService.TIME_SLOT_ENABLED.includes(
              scope.deliveryOptionConfig.delivery_time_slot_type,
            );

          if (scope.enabledTimeSlots) {
            timeSlotService
              .getAvailableTimeSlots(scope.deliveryOptionId)
              .then(({ data }) => {
                scope.showDefaultOption = true;
                scope.timeSlotOptions = timeSlotService.getTimeSlotSelectItems(
                  data,
                  { ignoreAvailable: true },
                );
              });
          }

          initDatePickerWatcher();
        }

        function initTimeSlot() {
          if (
            scope.timeSlotsTranslations &&
            Object.keys(scope.timeSlotsTranslations[0]).length === 0
          ) {
            scope.enabledTimeSlots = false;
            return;
          }

          if (!_.isEmpty(scope.timeSlots)) {
            scope.enabledTimeSlots = true;
            scope.timeSlotOptions = scope.timeSlots.map(function (item) {
              return {
                key: item.key,
                description: $filter('translate')(item.description),
              };
            });
          }

          if (!_.isEmpty(scope.timeSlotsTranslations)) {
            scope.showDefaultOption = true;
            scope.enabledTimeSlots = true;
            scope.timeSlotOptions = scope.timeSlotsTranslations.map(function (
              item,
            ) {
              return {
                key: $filter('translateModel')(item),
                description: $filter('translateModel')(item),
              };
            });
          }
        }

        scope.isRequired = function () {
          return scope.isTimeSlotWithLimitedQuantity
            ? scope.deliveryTimeSlotRequired
            : true;
        };

        function handleDateTimeSlotItems(selectedDate) {
          const date = isDate(selectedDate)
            ? dayjs(selectedDate).format('YYYY-MM-DD')
            : undefined;

          timeSlotService
            .getAvailableTimeSlots(scope.deliveryOptionId, date)
            .then(({ data }) => {
              scope.timeSlotOptions = timeSlotService.getTimeSlotSelectItems(
                data,
                { ignoreAvailable: isUndefined(selectedDate) },
              );
            });
        }

        function handleDateSlotItems(selectedDate) {
          if (!isDate(selectedDate)) {
            element.find('#delivery_time_slot_id').val('');
            element.find('#time_slot_group_key').val('');
            return;
          }

          const date = dayjs(selectedDate).format('YYYY-MM-DD');

          timeSlotService
            .getAvailableTimeSlots(scope.deliveryOptionId, date)
            .then(({ data }) => {
              const [timeSlot] = data;

              element.find('#delivery_time_slot_id').val(timeSlot._id);
              element.find('#time_slot_group_key').val(timeSlot.group_key);

              scope.onTimeSlotChange?.({
                value: {
                  cartTagId: scope.cartTagId,
                  delivery_time_slot_id: timeSlot._id,
                  time_slot_group_key: timeSlot.group_key,
                },
              });
            });
        }

        function initDatePickerWatcher() {
          scope.$watch('deliveryDate', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }

            switch (scope.deliveryOptionConfig.delivery_time_slot_type) {
              case 'date_time_slots':
                handleDateTimeSlotItems(newValue);
                return;
              case 'date':
                handleDateSlotItems(newValue);
                return;
              default:
                return;
            }
          });
        }

        init();
      },
    };
  },
]);
