import { PRODUCT_REVIEW_REWARD_TYPE } from '../constants/productReview';

app.service('productReviewCommentService', [
  '$http',
  'merchantService',
  'slFeatureService',
  'mainConfig',
  '$q',
  'userCreditService',
  'memberPointsService',
  function (
    $http,
    merchantService,
    slFeatureService,
    mainConfig,
    $q,
    userCreditService,
    memberPointsService,
  ) {
    const getProductReviewComments = (params) => {
      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          merchantService.merchantId +
          '/products/' +
          params.product_id +
          '/product_review_comments',
        params: {
          page: params.page,
          sort_by: 'score',
          order_by: 'desc',
        },
      });
    };

    const getUncommentedOrders = (params) => {
      return $http({
        method: 'GET',
        url: '/api/product_review_comments/orders',
        params: {
          is_product_reviewed: false,
          page: params.page,
          limit: params.limit,
          offset: (params.page - 1) * params.limit,
          user_id: mainConfig.currentUser._id,
          status: 'completed',
        },
      });
    };

    // enabled_product_review takes true as default, which means undefined should be enabled.
    const productReviewEnabled =
      slFeatureService.hasFeature('shopline_product_reviews') &&
      mainConfig.merchantData.product_setting.enabled_product_review !== false;
    const amazonProductReviewEnabled =
      slFeatureService.hasFeature('amazon_product_review') &&
      mainConfig.merchantData.product_setting.amazon_setting &&
      mainConfig.merchantData.product_setting.amazon_setting.status ===
        'active';

    const getOrder = (orderId) => {
      return $http({
        method: 'GET',
        url: '/api/orders/' + orderId,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        params: {
          user_id: mainConfig.currentUser._id,
        },
      });
    };

    const getSettings = () => {
      return $http({
        method: 'GET',
        url: '/api/settings/product_review',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };

    const getUserSettings = () => {
      return $http({
        method: 'GET',
        url: '/api/settings/users',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };

    const getProductReviewRewardConfig = () => {
      return $q
        .all([getSettings(), getUserSettings()])
        .then(([settingsRes, userSettingsRes]) => {
          const rewardType = settingsRes.data.data.product_review.enable_reward
            ? settingsRes.data.data.product_review.reward_type
            : null;
          const {
            enable_user_credit,
            enable_member_point,
          } = userSettingsRes.data.data;

          return {
            rewardType,
            enable_user_credit: enable_user_credit ?? true,
            enable_member_point,
          };
        })
        .then(({ rewardType, enable_user_credit, enable_member_point }) => {
          if (
            rewardType === PRODUCT_REVIEW_REWARD_TYPE.user_credit &&
            enable_user_credit
          ) {
            return userCreditService
              .getUserCreditRules({ rule_type: 'product_review_reward' })
              .then((res) => {
                return { rewardType, rule: res.data.items[0] };
              });
          } else if (
            rewardType === PRODUCT_REVIEW_REWARD_TYPE.member_point &&
            enable_member_point
          ) {
            return memberPointsService
              .getMemberPointRules({ rule_type: 'product_review_reward' })
              .then((res) => {
                return { rewardType, rule: res.data.items[0] };
              });
          }

          return { rewardType: null, rule: null };
        });
    };

    return {
      getProductReviewComments,
      getUncommentedOrders,
      productReviewEnabled,
      amazonProductReviewEnabled,
      getOrder,
      getSettings,
      getProductReviewRewardConfig,
    };
  },
]);
