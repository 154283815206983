/** 
 這支檔案沒有包含所有的 shoplytics tracker，
 舊有的 shoplytics tracker 還是在 service.trackers.js 中

 This file does not include all of the shoplytics trackers; 
 the existing shoplytics trackers are still in the service.trackers.js file.
 */

app.run([
  '$rootScope',
  'mainConfig',
  'trackerService',
  function ($rootScope, mainConfig, trackerService) {
    const { generalEventType } = trackerService;
    const userId = mainConfig.currentUser
      ? mainConfig.currentUser['_id']
      : null;

    function buildTrackDataForEventsUserFlow(event, data, userId) {
      const { event_category, property } = data;
      // value could be false, so we need to check if it's undefined or null
      const value =
        data.value === undefined || data.value === null ? null : data.value;

      return {
        event_category,
        user_id: userId,
        data: JSON.stringify({ property, value }),
      };
    }

    $rootScope.$on(generalEventType.MemberSignUpFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberSignUpFormSubmit, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.ThirdPartySignUpClick, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberLoginClick, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.SMSVerificationCodeSend, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.SMSVerificationFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(
      generalEventType.SMSVerificationSendButtonClick,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(generalEventType.SMSVerificationSubmit, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(
      generalEventType.RecaptchaVerificationStart,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(
      generalEventType.RecaptchaVerificationComplete,
      (event, data) => {
        const eventName = event.name;
        const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

        if (typeof shoplytics === 'function') {
          shoplytics('track', eventName, trackData);
        }
      },
    );

    $rootScope.$on(generalEventType.MemberSignUpInfoFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberSignUpInfoSubmit, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberSignUpSucceed, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberLoginFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.MemberLoginSucceed, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.UpdateMemberInfoFormShow, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });

    $rootScope.$on(generalEventType.UpdateMemberInfoSucceed, (event, data) => {
      const eventName = event.name;
      const trackData = buildTrackDataForEventsUserFlow(event, data, userId);

      if (typeof shoplytics === 'function') {
        shoplytics('track', eventName, trackData);
      }
    });
  },
]);
