app.service('familyMartFreezeService', [
  '$http',
  'multiCheckoutService',
  function ($http, multiCheckoutService) {
    const FAMILY_MART_FROZEN_DELIVERIES = [
      'sl_logistics_fmt_freeze_pay',
      'sl_logistics_fmt_freeze_nopay',
    ];

    const isFreezeType = (region_type) => {
      return FAMILY_MART_FROZEN_DELIVERIES.includes(region_type);
    };

    const checkFreeTypeWithFormData = (formData, multiCartDeliveries) => {
      if (multiCheckoutService.isEnabled()) {
        return Object.values(multiCartDeliveries).some((delivery) =>
          isFreezeType(delivery.delivery_option.region_type),
        );
      }
      return isFreezeType(formData.order.delivery_option.region_type);
    };

    const confirmStationSpace = (payload) => {
      return $http({
        method: 'POST',
        url: 'api/sl_logistics/station_spaces',
        data: payload,
      });
    };

    return {
      isFreezeType,
      confirmStationSpace,
      checkFreeTypeWithFormData,
    };
  },
]);
